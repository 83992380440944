@-webkit-keyframes openMenu {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes openMenu {
  0% {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media (max-width: 1024px) {
  .menu {
    background-color: #1f003e;
    width: 100%;
    height: 100vh;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 24px;
    padding-top: 0;
    padding-bottom: 0;
    -webkit-animation: openMenu 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
    animation: openMenu 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }

  .crossIcon {
    display: flex;
    justify-content: space-between;
    color: #52FFF2;
    font-size: 32px;
    margin-top: 16px;
    align-items: center;
  }
  .menuLink {
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-transform: capitalize;
    text-decoration: none;
    position: relative;
    padding: 19px 8px;
    color: #FBCDFF;
  }

  .menuLink::before {
    content: '';
    position: absolute;
    bottom: 12px;
    width: 0%;
    height: 1px;
  }

  .menuLink:hover:before {
    width: 24px;
  }
}
