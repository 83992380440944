@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Cabin:ital,wght@0,400..700;1,400..700&family=Inter:wght@100..900&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* scroll smooth */
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Cabin', sans-serif;
  background-color: #1f003e;
  /* background: linear-gradient(top, #1f003e, #1b0325); */
  /* background: -webkit-linear-gradient(#1f003e, #1b0325); */
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.horizontal-padding {
  padding-left: 105px;
  padding-right: 105px;
}
/* horizontal-padding for small screens  */
@media (max-width: 1024px) {
  .horizontal-padding {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.cabin {
  font-family: 'Cabin', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.inter {
  font-family: 'Inter', sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.text-shadow {
  text-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
}

.button {
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: linear-gradient(180deg, rgba(254, 200, 241, 0.9) -22.92%, rgba(254, 200, 241, 0) 26.73%),
    radial-gradient(137.13% 253.39% at 76.68% 66.67%, #602ea6 0%, #af52bd 100%);
  background-blend-mode: overlay, normal;
  box-shadow: 0px 0px 20px 0px rgba(204, 117, 219, 0.4), 0px 0px 5px 0px #fface4 inset;
}

.button:hover {
  transform: scale(1.05);
  transition: all 0.7s ease;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.5);

  background: linear-gradient(180deg, rgba(254, 200, 241, 0.9) -22.92%, rgba(237, 146, 215, 0) 20.75%),
    radial-gradient(89.95% 197.97% at 19.06% 100%, #541095 0%, #bd4ce0 100%);

  background-blend-mode: overlay, normal;
  box-shadow: -4px 0px 20px 0px rgba(188, 17, 218, 0.8), 0px 0px 5px 0px #fface4 inset;
}

.gradient-text {
  background: linear-gradient(148deg, #9c49c6 14.3%, #d563ae 61.54%, #cf87b4 86.63%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Cabin;
  font-style: normal;
  line-height: normal;
}

.gradient-box {
  width: 202.522px;
  height: 115.77px;
  transform: rotate(-41.689deg);
  flex-shrink: 0;
  background: linear-gradient(265deg, #db25ff 54.22%, #ffcaab 83.2%);
  opacity: 0.5;
  filter: blur(75px);
}

.platform-gradient {
  width: 270.947px;
  height: 323.596px;
  transform: rotate(179.754deg);
  flex-shrink: 0;
  background: conic-gradient(from 197deg at 50% 50%, #6972cd 0deg, #58297e 1.5567407011985779deg, #d5498a 360deg);
  filter: blur(300px);
}

.vision-gradient {
  width: 300.947px;
  height: 260.596px;
  transform: rotate(179.754deg);
  flex-shrink: 0;
  background: conic-gradient(from 197deg at 50% 50%, #6972cd 0deg, #58297e 1.5567407011985779deg, #d5498a 360deg);
  filter: blur(300px);
}

.image-outer-gradient {
  width: 250.947px;
  height: 323.596px;
  transform: rotate(179.754deg);
  flex-shrink: 0;
  background: conic-gradient(from 197deg at 50% 50%, #6972cd 0deg, #58297e 1.5567407011985779deg, #d5498a 360deg);
  filter: blur(300px);
}

.transform-vision-img {
  position: absolute;
  top: 50%;
  transform: translate(-10%, -50%);
}

.track-arrow-up-transform {
  transform: translate(50%, -4px);
}
@media (max-width: 1280px) {
  .track-arrow-up-transform {
    transform: translate(16%, -4px);
  }
}

.track-arrow-down-transform {
  transform: translate(50%, 4px);
}
@media (max-width: 1280px) {
  .track-arrow-down-transform {
    transform: translate(16%, 4px);
  }
}

.conic-gradient {
  width: 500px;
  height: 500px;
  background-image: conic-gradient(from 197deg at 50% 50%, #6972cd 0deg, #58297e 1.5567407011985779deg, #d5498a 360deg);
  filter: blur(300px);
}
.apy-text {
  background: linear-gradient(147deg, #9c49c6 44.75%, #d563ae 126.77%, #c17fa8 46.09%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: Cabin;
  font-style: normal;
  line-height: 150%;
}
